/** @jsx jsx */
import { ThemeContext } from '@emotion/core';
import {
  FinnoscoreComparisonSectionData,
  SelectedCountryContextProvider,
  SelectedFsTypeContextProvider,
  useDefaultCountry,
  useEmbeddedComparisonLink,
  useIsMounted,
  useSelectionBasedOnFsType,
} from '@modules';
import { WithFsType } from '@types';
import { useContext } from 'react';
import { GlobalStyles } from 'src/components/core/layout/GlobalStyles';
import { jsx, ThemeProvider } from 'theme-ui';
import { EmbeddedUITheme } from '../../../gatsby-plugin-theme-ui/embeddedThemes';
import { ColorContextProvider } from '../colorpicker';
import { finnoscoreDataStyles } from '../finnoscore-data/index.styles';
import Profile from '../finnoscore-data/Profile';
import ReportAdvertLink from '../finnoscore-data/ReportAdvertLink';
import { ComparisonLinkProvider } from '../finnoscore-list/context/ComparisonContext';
import { useTheme, useVisibleSections } from '../finnoscore-list/EmbeddedListPage';
import { FSCompareTabSelector, useFsSelectorOptions } from '../finnoscore-list/FSTabSelector';
import { FinnoscoreComparisonChart, FinnoscoreComparisonSection, FinnosoreComparisonSectionMeta } from './FinnoscoreComparisonSection';

export interface PageContextProps extends FinnosoreComparisonSectionMeta {
  pageContext: FinnoscoreComparisonSectionData & WithFsType;
}

const ComparePage = ({ isLoading, error, pageContext }: PageContextProps) => {
  const { countries, institutions, countryCalculations } = useSelectionBasedOnFsType(pageContext);
  const visibleSections = useVisibleSections();
  const tabOptions = useFsSelectorOptions(institutions);

  return (
    <FinnoscoreComparisonSection isLoading={isLoading} error={error} withSection={false}>
      {visibleSections.report && <ReportAdvertLink id='report' />}
      {visibleSections.contact && <Profile id='contact' />}

      <FSCompareTabSelector id='chartType' sx={finnoscoreDataStyles.tabSelector} options={tabOptions} />

      <FinnoscoreComparisonChart countries={countries} institutions={institutions} countryCalculations={countryCalculations} />
    </FinnoscoreComparisonSection>
  );
};

const PageWithLayout = ({ pageContext, isLoading, error }: PageContextProps) => {
  const { countries } = pageContext;
  const { country: selectedCountry } = useDefaultCountry(countries);

  const themeConfig = useContext(ThemeContext) as EmbeddedUITheme;
  const themeParam = useTheme();
  const isMounted = useIsMounted();

  /**
   * We use isMounted to prevent rendering until we're on the client side.
   * This ensures consistent rendering between server and client, preventing hydration errors.
   */
  if (!isMounted) {
    return null;
  }

  return (
    <ThemeProvider theme={themeParam}>
      <GlobalStyles />
      <SelectedFsTypeContextProvider>
        {selectedCountry ? (
          <SelectedCountryContextProvider defaultValue={selectedCountry}>
            <ColorContextProvider defaultColors={themeConfig.colors?.primaryRgbaObject}>
              <ComparisonLinkProvider useComparisonHook={useEmbeddedComparisonLink}>
                <ComparePage pageContext={pageContext} isLoading={isLoading} error={error} />
              </ComparisonLinkProvider>
            </ColorContextProvider>
          </SelectedCountryContextProvider>
        ) : (
          <FinnoscoreComparisonSection isLoading={isLoading} error={error} />
        )}
      </SelectedFsTypeContextProvider>
    </ThemeProvider>
  );
};

export default PageWithLayout;
